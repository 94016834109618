@import '../../theme/base/colors';
@import '../../theme/helpers/units';

$byDateColor: $dark-gray;
$byAuthorColor: $dark-gray;
$circleSize: 16px;
$circleRightMargin: 11px;
$lineWidth: 2px;

.f4eHistory__record {
  position: relative;
  padding-bottom: em(20px);
  padding-left: em($circleSize + $circleRightMargin);
}

.f4eHistory__record::before {
  position: absolute;
  left: 0;
  z-index: 20;
  display: block;
  width: em($circleSize);
  height: em($circleSize);
  content: '';
  background-color: white;
  border: em(2px) solid #049ce9;
  border-radius: 50%;
}

.f4eHistory__record::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: em(7px);
  z-index: 10;
  display: block;
  width: em($lineWidth);
  height: 100%;
  content: '';
  background-color: $light-gray;
}

.f4eHistory .f4eHistory__record:last-child::after {
  display: none;
}

.f4eHistory__record__by {
  margin-bottom: em(15px);
}

.f4eHistory__record__by__date {
  font-weight: 600;
  color: $byDateColor;
}

.f4eHistory__record__by__author {
  color: $byAuthorColor;
}

.f4eHistory__record__changes {
  margin-bottom: em(5px);
}

.f4eHistory__record__changes p {
  margin-bottom: 0.8em;
  line-height: 1.5em;
}

.f4eHistory__record__changes strong {
  font-weight: bold;
}
