@import '~@novo-electronique/react-button/dist';
@import '../../../theme/helpers/units';

.f4eSignInMicrosoftButton {
  @extend .novoButton;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  svg {
    margin: 0 em(12px) 0 0;
  }
}
