@import '../base/colors';
@import '../helpers/units';

$pillSmallFontSize: 12px;
$pillDefaultFontSize: 14px;
$pillDefaultBackground: $red;

.f4ePill {
  display: inline-block;
  width: em(28px, $pillDefaultFontSize);
  height: em(28px, $pillDefaultFontSize);
  font-size: $pillDefaultFontSize;
  line-height: em(28px, $pillDefaultFontSize);
  color: $white;
  text-align: center;
  background-color: $pillDefaultBackground;
  border-radius: 50%;
}

.f4ePill--small {
  width: em(18px, $pillSmallFontSize);
  height: em(18px, $pillSmallFontSize);
  font-size: $pillSmallFontSize;
  line-height: em(18px, $pillSmallFontSize);
}
