@import '../../../../theme/helpers/units';
@import '../../../../theme/base/colors';

$menuItemColor: white;
$menuItemBackgroundColor: #363636;
$menuItemSelectedBackgroundColor: $blue;
$subMenuItemBackgroundColor: $blue;
$subMenuItemSelectedColor: black;
$subMenuItemSelectedBackgroundColor: white;

.f4eMenu {
  display: flex;
  flex-direction: row;
}

.f4eMenu__item {
  display: flex;
  align-items: stretch;
  margin-right: em(8px);
  font-size: em(16px);
  background-color: $menuItemBackgroundColor;
}

.f4eMenu__item--selected {
  background-color: $menuItemSelectedBackgroundColor;
}

.f4eMenu__item__link {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: em(16px) em(24px);
  color: $menuItemColor;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}

.f4eMenu__item--selected .f4eMenu__item__link {
  cursor: default;
}

// sub menu compositions

.f4eMenu--sub-menu .f4eMenu__item {
  background-color: $subMenuItemBackgroundColor;
}

.f4eMenu--sub-menu .f4eMenu__item__link {
  padding: em(10px) em(16px);
  text-transform: initial;
}

.f4eMenu--sub-menu .f4eMenu__item--selected {
  background-color: $subMenuItemSelectedBackgroundColor;
}

.f4eMenu--sub-menu .f4eMenu__item--selected .f4eMenu__item__link {
  color: $subMenuItemSelectedColor;
}
