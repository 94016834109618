// Theme
// -------------------------------
@import 'html';
@import 'base/all';
@import 'objects/all';

html,
body,
#f4e {
  height: 100%;
}

.f4eIssueColumn__warning {
  color: $dark-gray;
}

.f4eImportantMention {
  font-weight: 600;
}

.f4eForm--no-required-symbol {
  --novo-field-label-required-symbol: '';
}

.f4eForm--no-error .novoFormFieldError {
  display: none;
}

.novoButton {
  white-space: nowrap;
}

.novoForm {
  --novo-field-readonly-margin: auto 0 1.125em;
}

.novoForm::after,
.novoTabPanel::after {
  display: block;
  clear: both;
  content: '';
}

.novoForm__fields .novoButton {
  margin-left: em(12px);
}

.novoSidePanel__header {
  --novo-side-panel-header-title-line-height: 1.2em;
}

.novoSidePanel__header .novoSidePanel__header__close {
  line-height: 1.55em;
}

.novoSidePanel__header__title {
  display: flex;
  align-items: flex-start;
  padding-bottom: 0.3em;
}

.novoSidePanel__header__title .f4eToolbar {
  margin-right: 1em;
  font-size: 0.5em;
}

.novoSidePanel__content__title {
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: 600;
}

.novoForm--readonly .novoFormField--checkbox {
  .novoFormField__input {
    appearance: none;
    margin-top: 0;
    font-family: 'Material Icons', sans-serif;

    &::before {
      color: $medium-dark-gray;
    }

    &[type='checkbox']::before {
      content: 'check_box_outline_blank';
    }

    &[type='checkbox']:checked::before {
      content: 'check_box';
    }

    &[type='radio']::before {
      content: 'radio_button_unchecked';
    }

    &[type='radio']:checked::before {
      content: 'radio_button_checked';
    }
  }

  .novoFormField__input:checked + .novoFormFieldLabel {
    color: black;
  }
}

.novoForm--readonly .novoInputMultiSelect__multi-value__label {
  color: black;
}

// stylelint-disable-next-line
.f4eNoFilterIcon .ag-filter-icon {
  display: none;
}

// stylelint-disable-next-line
.ag-watermark {
  display: none !important;
}

@media print {
  noscript {
    display: none;
  }
}
