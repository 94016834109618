@import '../../../../theme/base/colors';
@import '../../../../theme/base/elevations';
@import '../../../../theme/helpers/units';

$simpleLayoutTitleFontSize: 32px;
$simpleContentTitleFontSize: 24px;
$simpleLayoutBackgroundColor: $lightest-gray;
$simpleLayoutContentBorderColor: #e9e9eb;
$simpleLayoutLogoColor: #040570;
$simpleLayoutLinkColor: $dark-blue;

.f4eSimpleLayout {
  min-height: 100vh;
  padding: em(48px) em(24px) em(72px);
  background-color: $simpleLayoutBackgroundColor;

  a {
    color: $simpleLayoutLinkColor;
  }

  .f4eForm__fieldset {
    padding-top: em(24px);
  }

  .f4eToolbar {
    justify-content: flex-end;
  }
}

.f4eSimpleLayout__panel {
  max-width: em(1140px);
  margin: auto;
}

.f4eSimpleLayout__panel__header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto em(60px) auto;
}

.f4eSimpleLayout__panel__header--titled {
  justify-content: space-between;
}

.f4eSimpleLayout__panel__header__logo {
  display: block;
  width: em(238px);
  fill: $simpleLayoutLogoColor;
}

.f4eSimpleLayout__panel__header__title {
  padding-left: em(24px, $simpleLayoutTitleFontSize);
  font-size: em($simpleLayoutTitleFontSize);
  font-weight: 700;
  line-height: em(48px, $simpleLayoutTitleFontSize);
  text-align: right;
  letter-spacing: -0.01em;
}

.f4eSimpleLayout__panel__content {
  position: relative;
  padding: em(32px) em(48px);
  margin: 0 auto;
  background: white;
  filter: $elevation-02;
  border: 1px solid $simpleLayoutContentBorderColor;
}

.f4eSimpleLayout__panel__content__title {
  margin: em(8px, $simpleContentTitleFontSize) 0 em(32px, $simpleContentTitleFontSize);
  font-size: em($simpleContentTitleFontSize);
  font-weight: 700;
  line-height: em(36px, $simpleContentTitleFontSize);
}

.f4eSimpleLayout__panel__content__text {
  font-size: 1em;
  line-height: 1.5em;
}

.f4eSimpleLayout__panel__content__text strong {
  font-weight: 600;
}

.f4eSimpleLayout__panel__content__text i {
  font-style: italic;
}

.f4eSimpleLayout--medium .f4eSimpleLayout__panel {
  max-width: em(900px);
}

.f4eSimpleLayout--small {
  .f4eSimpleLayout__panel {
    max-width: em(520px);
  }

  .f4eSimpleLayout__panel__header {
    margin: 0 auto em(48px) auto;
  }
}

@media print {
  .f4eSimpleLayout {
    max-width: 100%;
    padding: 0;
    background: transparent;
  }

  .f4eSimpleLayout__panel__header {
    align-items: flex-start;
    margin: 12pt auto 20pt;
  }

  .f4eSimpleLayout__panel__header__title {
    margin-left: 2em;
    font-size: 17pt;
    line-height: 1.15em;
  }

  .f4eSimpleLayout__panel__header__logo {
    width: auto;
    height: 56pt;
  }

  .f4eSimpleLayout__panel__content {
    padding: 0;
    filter: none;
    border: 0;
  }

  .f4eToolbar {
    display: none;
  }
}
