@import '../../../../theme/helpers/units';

.f4eLayout {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.f4eLayout__header {
  display: flex;
  align-items: center;
  padding: em(24px);
}

.f4eLayout__header__title {
  flex-shrink: 1;
  padding-right: em(16px, 32px);
  font-size: em(32px);
  font-weight: 600;
}

.f4eLayout__header__secondary {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
}

.f4eLayout__content {
  flex-grow: 1;
}

.f4eLayout__content--no-grid {
  padding: 0 em(24px) em(24px);
}

.f4eLayout__content > .novoForm--inline .novoForm__fields {
  padding-right: 0.75em;
  padding-left: 0.75em;
}
