@import '../../theme/base/colors';
@import '../../theme/helpers/units';

.f4eDefinitions {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: em(8px) em(16px);
  background-color: $lightest-gray;
}

.f4eDefinitions__part {
  width: 50%;
  padding: em(8px) 0;
}

.f4eDefinitions__part--term {
  font-weight: bold;
}
