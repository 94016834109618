@import '../../../theme/base/colors';
@import '../../../theme/helpers/units';

$mergeMembersTableRowBorderColor: #d3d4d8;
$mergeMembersTableRowBgColor: $lightest-gray;
$mergeMembersTableRowConflictsBgColor: #fff9ee;
$mergeMembersTableRowInputCheckedColor: $green;
$mergeMembersTableRowInputColor: $medium-gray;
$mergeMembersTableRowLabelColor: $dark-gray;
$mergeMembersTableRowLabelCheckedColor: $black;
$mergeMembersTableRowLabelDisabled: #7f8c8f;

.f4eMergeMembersTableRow__cell {
  padding: em(10px) em(20px);
  overflow: hidden;
  line-height: em(24px);
  vertical-align: middle;
  border-bottom: 1px solid $mergeMembersTableRowBorderColor;

  &:nth-child(1),
  &:nth-child(3),
  &:nth-child(2) {
    background-color: $mergeMembersTableRowBgColor;
  }
}

.f4eMergeMembersTableRow__cell--name {
  font-weight: 600;
}

.f4eMergeMembersTableRowInput__label {
  color: $mergeMembersTableRowLabelColor;
}

.f4eMergeMembersTableRow--conflicts .f4eMergeMembersTableRow__cell {
  &:nth-child(1),
  &:nth-child(3),
  &:nth-child(2) {
    background-color: $mergeMembersTableRowConflictsBgColor;
  }
}

.f4eMergeMembersTableRowInput {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.f4eMergeMembersTableRowInput__input {
  margin-top: 0 #{em(11px)} 0 0;
  font-family: 'Material Icons', sans-serif;
  appearance: none;

  &::before {
    font-size: em(26px);
    color: $mergeMembersTableRowInputColor;
    cursor: pointer;
    content: 'check_box_outline_blank';
    appearance: none;
  }

  &:checked::before {
    color: $mergeMembersTableRowInputCheckedColor;
    content: 'check_box';
  }
}

.f4eMergeMembersTableRowInput__input:checked + .f4eMergeMembersTableRowInput__label {
  color: $mergeMembersTableRowLabelCheckedColor;
}

.f4eMergeMembersTableRow--no-conflict {
  .f4eMergeMembersTableRowInput {
    cursor: default;
  }

  .f4eMergeMembersTableRowInput__input::before {
    color: $mergeMembersTableRowInputColor;
    cursor: default;
    content: 'check_box';
  }

  .f4eMergeMembersTableRowInput__label {
    color: $mergeMembersTableRowLabelDisabled;
  }
}
