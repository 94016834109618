// Colors
// -------------------------------

$black: #000;
$dark-gray: #575756;
$medium-dark-gray: #737373;
$medium-gray: #a6afb1;
$light-gray: #e9eff0;
$lightest-gray: #f7f9fa;
$white: #fff;
$dark-blue: #0d5575;
$blue: #047eb6;
$highlight-blue: #049ce9;
$light-blue: #7cd0f6;
$lightest-blue: #d2f1ff;
$red: #d02b2b;
$yellow: #fead0f;
$green: #11ba40;
