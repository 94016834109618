@import '../../../theme/base/colors';
@import '../../../theme/helpers/units';
@import '../../../theme/objects/table';

$gricsImportEntriesTableCreatedStatusColor: $green;
$gricsImportEntriesTableModifiedStatusColor: $blue;
$gricsImportEntriesTableUnchangedStatusColor: darken($medium-gray, 5%);
$gricsImportEntriesTableErrorStatusColor: $red;
$gricsImportEntriesTableRowErrorBgColor: rgba($yellow, 0.15);

.f4eGricsImportEntriesTable {
  @extend .f4eTable;

  th {
    background-color: transparent;
    border-bottom-width: 3px;
  }

  td {
    border-bottom-width: 1px;
  }

  tr:last-child td {
    border-bottom-width: 2px;
  }

  .f4eGricsImportEntriesTable__row--error {
    background-color: $gricsImportEntriesTableRowErrorBgColor;
  }

  .f4eGricsImportEntriesTable__row__cell {
    vertical-align: middle;
  }

  .f4eGricsImportEntriesTable__row__cell--amount,
  .f4eGricsImportEntriesTable__row__cell--status {
    width: 100px;
    text-align: right;
  }

  td.f4eGricsImportEntriesTable__row__cell--status {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: 600;
  }

  .f4eGricsImportEntriesTable__row__cell--status[data-value='created'] {
    color: $gricsImportEntriesTableCreatedStatusColor;
  }

  .f4eGricsImportEntriesTable__row__cell--status[data-value='modified'] {
    color: $gricsImportEntriesTableModifiedStatusColor;
  }

  .f4eGricsImportEntriesTable__row__cell--status[data-value='unchanged'] {
    color: $gricsImportEntriesTableUnchangedStatusColor;
  }

  .f4eGricsImportEntriesTable__row__cell--status[data-value='error'] {
    color: $gricsImportEntriesTableErrorStatusColor;
  }
}

.f4eGricsImportErrorTooltip {
  margin-left: 3px;
  font-size: 20px;
  cursor: default;
  user-select: none;

  &:hover {
    opacity: 0.8;
  }
}
