@import '../../../../theme/base/colors';
@import '../../../../theme/helpers/units';

$alertBgColor: $yellow;

.f4eAlert {
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5em 1.5em;
  background: $yellow;
}

.f4eAlert__message {
  flex: 1;
  font-weight: 600;
}

.f4eAlert__message a {
  color: black;
}

.f4eAlert__button {
  cursor: pointer;
  background: none;
  border: 0;
  opacity: 0.9;
}
