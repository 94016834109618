@import '../../../../theme/helpers/units';

.f4eWizardStep {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.f4eWizardStep__directive {
  margin-bottom: em(18px);
  line-height: em(24px);
  text-align: center;
}

.f4eWizardStep__content {
  flex: 1;
  overflow: auto;
}
