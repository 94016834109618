@import '../../../../theme/base/colors';
@import '../../../../theme/helpers/units';

$accordionTitleFontSize: 16px;
$accordionBorderColor: $light-gray;
$accordionItemVisibleBgColor: $light-gray;

.f4eAccordion {
  margin-bottom: 1.5em;
  border: 2px solid $accordionBorderColor;
  border-radius: em(4px);
}

.f4eAccordionItem {
  position: relative;
  top: -1px;
}

.f4eAccordionItem__title {
  font-size: em($accordionTitleFontSize);
  font-weight: 600;
  border-bottom: 2px solid $accordionBorderColor;
}

.f4eAccordionItem__title__button {
  display: flex;
  width: 100%;
  padding: em(12px, $accordionTitleFontSize) em(16px, $accordionTitleFontSize);
  font-size: inherit;
  font-weight: inherit;
  text-align: left;
  cursor: pointer;
  background-color: inherit;
  border: 0;

  &::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: '';
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23575756'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  }
}

.f4eAccordionItem__content {
  overflow: hidden;
  transition: max-height ease-out;
}

.f4eAccordionItem__content__wrapper {
  padding: em(6px) em(16px) em(16px);
}

.f4eAccordionItem--visible {
  .f4eAccordionItem__title {
    background-color: $accordionItemVisibleBgColor;
  }

  .f4eAccordionItem__title__button::after {
    opacity: 1;
    transform: rotate(-180deg);
  }

  .f4eAccordionItem__content {
    border-bottom: 2px solid $accordionBorderColor;
  }
}

.f4eAccordionItem:last-child {
  .f4eAccordionItem__title,
  .f4eAccordionItem__content {
    border-bottom: 0;
  }
}

.f4eAccordionItem--no-animation .f4eAccordionItem__content {
  transition: none;
}
