@import '../../../theme/helpers/units';
@import '../../../theme/base/colors';

$headerPrimarySectionBackgroundColor: #363636;
$headerSecondarySectionBackgroundColor: $blue;
$headerLogoWidth: 150px;
$headerLogoMarginRight: 30px;
$headerPaddingTopBottom: em(17px);

.f4eHeader__section {
  display: flex;
  padding: $headerPaddingTopBottom em(30px) 0 em(24px);
  background-color: $headerPrimarySectionBackgroundColor;
}

.f4eHeader__section--secondary {
  padding-top: em(8px);
  background-color: $headerSecondarySectionBackgroundColor;
}

.f4eHeader__section__logo {
  position: relative;
  top: em(10px);
  width: em($headerLogoWidth);
  margin-top: em(-15px);
  margin-right: em(30px);
  fill: white;

  .f4eLogoLongName {
    display: none;
  }
}

.f4eHeader__section__navigation {
  flex-grow: 2;
}

.f4eHeader__section__navigation .f4eMenu--sub-menu {
  margin-left: em($headerLogoWidth + $headerLogoMarginRight);
}

.f4eHeader__section__currentUser {
  display: flex;
  align-items: center;
  padding-bottom: $headerPaddingTopBottom;
  margin-left: auto;
}
