@import '../../../theme/base/colors';
@import '../../../theme/helpers/units';

$importSectionHeaderStatusFontSize: 20px;
$importSectionHeaderStatusSuccessColor: $green;
$importSectionHeaderStatusErrorColor: darken($yellow, 5%);

.f4eImportSectionHeader {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding-right: 1em;
}

.f4eImportSectionHeader__name {
  display: flex;
  align-items: center;
}

.f4eImportSectionHeader__name__status {
  padding-right: em(10px, $importSectionHeaderStatusFontSize);
  font-size: em($importSectionHeaderStatusFontSize);

  &::after {
    color: $importSectionHeaderStatusSuccessColor;
    content: 'check_circle';
  }
}

.f4eImportSectionHeader__name__status--error::after {
  color: $importSectionHeaderStatusErrorColor;
  content: 'warning';
}

.f4eImportSectionHeader__count {
  font-size: em(14px, 16px);
  font-weight: normal;
}

.f4eImportSectionNoDetails {
  margin: 0.75em 0;
}
