@import '../../../../theme/base/colors';
@import '../../../../theme/helpers/units';

$backgroundTaskBgColor: #363636;
$backgroundTaskCancelBgColor: $light-gray;
$backgroundTaskCancelBgColorHovered: darken($light-gray, 5%);

.f4eBackgroundTaskFeedback {
  cursor: default;
  background-color: $backgroundTaskBgColor;
}

.f4eBackgroundTaskFeedback__content {
  display: flex;
  align-items: center;
  padding: 0 em(6px);
}

.f4eBackgroundTaskFeedback__content__feedback {
  position: relative;
  padding-right: 1em;
}

.f4eBackgroundTaskFeedback__content__feedback__cancel {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 1.5em;
  height: 1.5em;
  padding: 0;
  font-size: 1em;
  text-align: center;
  cursor: pointer;
  background-color: $backgroundTaskCancelBgColor;
  border: 0;
  border-radius: 50%;

  &:hover {
    background-color: $backgroundTaskCancelBgColorHovered;
  }
}

.f4eBackgroundTaskFeedback__content__text {
  flex: 1;
  line-height: 1.25em;
}

.f4eBackgroundTaskFeedback .novoCircularProgress {
  width: 3em;
  height: 3em;
  font-size: 0.5em;
}

.f4eBackgroundTaskFeedback:hover .f4eBackgroundTaskFeedback__content__feedback__cancel {
  display: block;
}

@media print {
  .f4eBackgroundTaskFeedback {
    display: none;
  }
}
