@import '../../../theme/helpers/units';

/* Form */
.f4eSignInForm .novoFormField {
  --novo-field-label-required-symbol: '';

  padding: 0;
  margin-bottom: em(34px);
}

.f4eSignInForm .novoFormField__input:disabled {
  margin-bottom: -1em;
}

.f4eSignInForm__footer {
  display: flex;
  align-items: center;
  margin-top: em(25px);
}

.f4eSignInForm__footer__col {
  flex: 1;
}

.f4eSignInForm__footer__col--back {
  text-align: right;
}
