@import '../../../theme/helpers/units';

$initializingSize: 120px;

.f4eInitializing {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.f4eInitializing__image {
  width: em($initializingSize);
  height: em($initializingSize);
}
