@import '../../../../theme/helpers/units';

.f4ePeriodSelector {
  display: flex;
}

.f4ePeriodSelector .novoFormField__input {
  width: auto;
}

.f4ePeriodSelector .novoFormField__input:not(:first-child) {
  margin-left: em(12px);
}

.f4ePeriodSelector--small .novoFormField__input {
  height: auto;
  padding: var(--novo-field-input-inline-padding);
  padding-right: 1.75em;
}
