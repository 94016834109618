@import '../../../../theme/helpers/units';

.f4eContentBox {
  padding: em(24px);
  margin-bottom: em(24px);
  line-height: 1.5em;
  background: white;
  border: 1px solid #e9e9eb;
}

.f4eContentBox__title {
  margin-bottom: em(15px);
  font-size: em(20px);
  font-weight: 700;
}

.f4eContentBox__text strong {
  display: block;
  margin-bottom: em(27px);
}
