// Typography
// -------------------------------
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

$font-size-normal: 16px;
$font-primary: 'Open Sans', sans-serif;

html,
body,
button,
input,
select {
  font-family: $font-primary;
}

strong {
  font-weight: 700;
}
