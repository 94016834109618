@import '../base/colors';
@import '../helpers/units';

$tableFontSize: 13px;

.f4eTable {
  width: 100%;
  border-collapse: inherit;

  th,
  td {
    padding: 10px;
    font-size: $tableFontSize;
    text-align: left;
    border-bottom: 2px solid $light-gray;
  }

  th {
    font-weight: 600;
    background-color: $light-gray;
  }

  .f4eTable__row__cell--period {
    width: 100px;
    padding-right: 50px;
  }

  td.f4eTable__row__cell--period {
    text-align: center;
  }

  th.f4eTable__row__cell--amount,
  td.f4eTable__row__cell--amount {
    width: 100px;
    text-align: right;
  }

  .f4eTable__row--detail > td:last-child {
    padding-right: 0;
  }

  .f4eTable__row--selectable {
    cursor: pointer;
  }

  .f4eTable__row--selectable:hover {
    td {
      background-color: $lightest-blue;
    }
  }

  .f4eTable__row--selected {
    background-color: #b3d8e9;
  }

  td.f4eTable__row__cell--empty-notification {
    text-align: center;
  }
}

.f4eTable--centered {
  th:not(:first-child),
  td:not(:first-child) {
    text-align: center;
  }
}
