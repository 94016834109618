@import '../../theme/base/colors';
@import '../../theme/helpers/units';

$mergeFormModalLinkButtonColor: $blue;
$mergeFormModalLinkButtonColorHovered: darken($blue, 5%);

.f4eMergeFormModalConfirmSection {
  display: flex;
  flex-direction: column;
  max-width: em(1024px);
  margin: 1em auto;
}

.f4eMergeFormModalLinkButton {
  display: inline;
  padding: 0;
  font-size: inherit;
  line-height: inherit;
  color: $mergeFormModalLinkButtonColor;
  text-decoration: underline;
  cursor: pointer;
  background: transparent;
  border: none;

  &:hover {
    color: $mergeFormModalLinkButtonColorHovered;
  }
}
