@import '../../../theme/helpers/units';

.f4eMemberChangesList {
  width: 100%;
  margin-bottom: em(34px);
  table-layout: fixed;
}

.f4eMemberChangesList__header__cell {
  font-weight: 600;
  line-height: 1.5em;
  text-align: left;
}

.f4eMemberChangesList__header__cell--separator {
  width: em(40px);
}

.f4eMemberChangesList__content__property {
  font-size: em(14px);
  line-height: em(32px, 14px);
}

.f4eMemberChangesList__content__property td {
  padding-top: em(6px);
}

.f4eMemberChangesList__content__value__current,
.f4eMemberChangesList__content__value__new {
  padding: em(8px) em(16px);
  line-height: em(32px);
}

.f4eMemberChangesList__content__value__current {
  background-color: #f0f1f3;
}

.f4eMemberChangesList__content__value__separator {
  text-align: center;
}

.f4eMemberChangesList__content__value__new {
  border: 1px solid #d3d4d8;
}

.f4eMemberChangesList__content__value {
  line-height: 2em;
}
