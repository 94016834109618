@import '../../../theme/base/colors';
@import '../../../theme/helpers/units';

$mergeMemberTableHeaderBorderColor: #d3d4d8;
$mergeMemberTableHeaderFontSize: 20px;
$mergeMemberTableHeaderSymbolColor: $medium-gray;

.f4eMergeMembersTable {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
}

.f4eMergeMembersTable__header {
  position: sticky;
  top: 0;
  background-color: white;
}

.f4eMergeMembersTable__header__cell {
  padding: em(10px, $mergeMemberTableHeaderFontSize) em(20px, $mergeMemberTableHeaderFontSize)
    em(12px, $mergeMemberTableHeaderFontSize);
  font-size: em($mergeMemberTableHeaderFontSize);
  font-weight: 600;
  line-height: em(28px, $mergeMemberTableHeaderFontSize);
  text-align: left;
  letter-spacing: -0.01em;
  border-bottom: 1px solid $mergeMemberTableHeaderBorderColor;
}

.f4eMergeMembersTableHeaderContent {
  display: flex;
}

.f4eMergeMembersTable__header__cell:nth-child(2) .f4eMergeMembersTableHeaderContent::after,
.f4eMergeMembersTable__header__cell:nth-child(3) .f4eMergeMembersTableHeaderContent::after {
  flex: 1;
  font-weight: 500;
  color: $mergeMemberTableHeaderSymbolColor;
  text-align: center;
}

.f4eMergeMembersTable__header__cell:nth-child(2) .f4eMergeMembersTableHeaderContent::after {
  font-family: 'Material Icons', sans-serif;
  font-size: em(20px);
  content: 'arrow_forward';
}

.f4eMergeMembersTable__header__cell:nth-child(3) .f4eMergeMembersTableHeaderContent::after {
  font-size: em(24px);
  content: '=';
}
