@import '../../../theme/base/colors';
@import '../../../theme/helpers/units';

.f4eEmailFilters {
  --novo-button-inline-margin: 0 0.375em;

  .novoForm {
    flex-direction: column;
    align-items: flex-start;
  }

  .novoForm__fields {
    flex-wrap: wrap;
    padding-bottom: 1em;

    &:disabled {
      --novo-field-label-required-color: transparent;
    }
  }

  .novoFormFieldError {
    display: none;
  }

  .novoButton {
    width: rem(40px);
    height: rem(40px);
    padding: 0;
    font-size: em(24px);
    line-height: 0;

    // stylelint-disable-next-line
    .material-icons {
      color: $white;
    }

    &:disabled {
      background-color: $medium-gray;
      border-color: $medium-gray;
    }
  }
}

.f4eEmailFilters__label {
  display: flex;
  column-gap: rem(6px);
  align-items: center;
  margin-bottom: rem(4px);
  font-weight: 700;
  cursor: pointer;
  user-select: none;
}

.f4eEmailFilters__row {
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
  align-items: flex-end;
}

.f4eEmailFilters__row--badges {
  column-gap: 1em;
  padding-right: 1em;
  padding-bottom: 0;
  padding-left: 0.75em;
}

.f4eEmailFilterBadge {
  display: inline-flex;
  align-items: center;
  padding: em(6px) em(12px);
  margin-top: 1em;
  color: $dark-gray;
  user-select: none;
  background-color: $light-gray;
  border-radius: rem(6px);

  b {
    font-weight: 600;
  }
}

.f4eEmailFilterBadge__button {
  padding-right: 0;
  padding-left: em(12px);
  line-height: 0;
  cursor: pointer;
  background: none;
  border: none;
  outline: 0;
}

.f4eEmailFilterBadge--disabled .f4eEmailFilterBadge__button {
  cursor: default;
}
