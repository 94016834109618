@import '../../theme/helpers/units';

.f4eConciliationReport {
  width: 100%;
  font-size: 16px;
  border-spacing: 0 0;
  border-collapse: separate;
}

.f4eConciliationReport td,
.f4eConciliationReport th {
  padding: em(10px) em(5px);
  text-align: right;
}

.f4eConciliationReport tr td:first-child,
.f4eConciliationReport tr th:first-child {
  text-align: left;
}

.f4eConciliationReport__body td {
  border-bottom: 1px solid silver;
}

.f4eConciliationReport__body tr:last-child td {
  border-bottom: none;
}

.f4eConciliationReport__header {
  font-weight: bold;
}

.f4eConciliationReport__header th {
  border-bottom: 2px solid black;
}

.f4eConciliationReport__footer {
  font-weight: bold;
}

.f4eConciliationReport__footer td {
  border-top: 2px solid black;
  border-bottom: none;
}

@media print {
  .f4eReport:not(.f4eReport--with-header):not(:first-of-type) .f4eReport__header__print {
    display: none;
  }

  .f4eConciliationReport__header th {
    font-size: 7pt;
  }

  .f4eConciliationReport__body td,
  .f4eConciliationReport__footer td {
    font-size: 7pt;
  }
}
