@import '../../../../theme/base/colors';
@import '../../../../theme/helpers/units';

$optionsBgColor: #fff;
$optionsBorderColor: $light-gray;
$optionsButtonFontSize: 14px;
$optionsButtonColor: $dark-gray;
$optionsButtonHoveredBgColor: $lightest-gray;

.f4eDropdownButton {
  position: relative;
  display: inline-block;
}

.f4eDropdownButton__options {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  background-color: $optionsBgColor;
  border: var(--novo-button-border);
  border-radius: var(--novo-button-border-radius);
  transform: translateY(1px);
}

.f4eDropdownButton__options--small {
  font-size: 1em;
}

.f4eDropdownButton__options--normal {
  margin-left: var(--novo-button-normal-action-padding-left);
  font-size: var(--novo-button-normal-font-size);
}

.f4eDropdownButton__options__item {
  flex-basis: 100%;
}

.f4eDropdownButton__options__item__button {
  width: 100%;
  padding: em(6px, $optionsButtonFontSize) em(16px, $optionsButtonFontSize);
  font-size: em($optionsButtonFontSize);
  color: $optionsButtonColor;
  text-align: left;
  white-space: nowrap;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;

  &:hover {
    background-color: $optionsButtonHoveredBgColor;
  }
}
