@import '../../../../theme/helpers/units';
@import '../../../../theme/objects/buttons';

.f4eToolbar {
  display: flex;
  margin-left: auto;
}

.f4eToolbar > .novoButton,
.f4eToolbar > .f4eDropdownButton {
  @extend .f4eButtonWithIcon;

  margin-left: em(16px);
}

.f4eToolbar .novoButton--small.novoButton--action {
  --novo-button-small-progress-top: 1em;
  --novo-button-small-progress-left: 1em;
  --novo-circular-progress-width: 2.5em;
  --novo-circular-progress-height: 2.5em;
  --novo-circular-progress-padding: 0.25em;

  .novoCircularProgress {
    display: block;
  }
}

.f4eToolbar .novoButton--is-loading .f4eIcon {
  visibility: hidden;
}
