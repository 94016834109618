@import '../../../theme/helpers/units';

$errorMessageBackgroundColor: #fff;
$errorMessageBorderColor: #e9e9eb;
$errorMessageHPadding: 48px;
$errorMessageVPadding: 24px;
$errorMessageTitleFontSize: 32px;
$errorMessageSubTitleFontSize: 16px;

.f4eErrorMessage {
  position: relative;
  width: em(1140px);
  padding: em($errorMessageVPadding) em($errorMessageHPadding);
  margin: em(48px) auto 0 auto;
  background: $errorMessageBackgroundColor;
  border: 1px solid $errorMessageBorderColor;
  box-shadow: 0 1px 2px rgb(87 87 86 / 10%);
}

.f4eErrorMessage--hosted {
  width: unset;
  padding: unset;
  margin: unset;
  border: unset;
  box-shadow: unset;
}

.f4eErrorMessage__title {
  margin-bottom: em(27px, $errorMessageTitleFontSize);
  font-size: em($errorMessageTitleFontSize);
  font-weight: 700;
}

.f4eErrorMessage__sub-title {
  margin-bottom: em(27px, $errorMessageSubTitleFontSize);
  font-size: em($errorMessageSubTitleFontSize);
  font-weight: 600;
}

.f4eErrorMessage__description {
  margin-bottom: em(27px);
}

.f4eErrorMessage__glyph {
  position: absolute;
  top: em($errorMessageVPadding);
  right: em($errorMessageHPadding);
}
