@import '../../../theme/base/colors';
@import '../../../theme/helpers/units';

$userInfoColor: white;
$userInfoLineHeight: 24px;
$userInfoIconColor: $medium-gray;
$userActionsColor: white;
$userActionsBackgroundColor: $dark-gray;
$userActionsBackgroundHColorHovered: #363636;
$userActionsBorderColor: #8b90a0;

.f4eCurrentUserMenu {
  position: relative;
}

.f4eCurrentUserMenu__userInfo {
  display: flex;
  align-items: center;
  padding: 0;
  font-size: em(14px);
  font-weight: 600;
  color: $userInfoColor;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;

  &:hover {
    opacity: 0.9;
  }
}

.f4eCurrentUserMenu__userInfo__profile {
  margin-right: em(10px, 24px);
  color: $userInfoIconColor;
}

.f4eCurrentUserMenu__userInfo__chevronDown {
  margin-left: em(5px, 24px);
  color: $userInfoIconColor;
}

.f4eCurrentUserMenu__actions {
  position: absolute;
  width: 100%;
  margin-top: em($userInfoLineHeight * 0.4);
  background-color: $userActionsBackgroundColor;
  border: 1px solid $userActionsBorderColor;
  box-shadow: 0 em(3px) em(6px) rgb(0 0 0 / 10%);
}

.f4eCurrentUserMenu__actions__item {
  padding: em(10px);
  color: $userActionsColor;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid $userActionsBorderColor;
  }

  &:hover {
    background-color: $userActionsBackgroundHColorHovered;
  }
}

.f4eCurrentUserMenu__actions__item__button {
  width: 100%;
  padding: 0;
  font-size: em(14px);
  line-height: em(24px);
  color: inherit;
  text-align: left;
  cursor: inherit;
  background-color: transparent;
  border: 0;
  outline: 0;
}
