// Elevations
// -------------------------------
@import '../helpers/units';

$elevation-01: none;
$elevation-02: drop-shadow(0 em(1px) em(2px) rgb(87 87 86 / 10%));
$elevation-03: drop-shadow(0 em(4px) em(8px) rgb(87 87 86 / 10%));
$elevation-04: drop-shadow(0 em(6px) em(12px) rgb(87 87 86 / 10%));
$elevation-05: drop-shadow(0 em(8px) em(16px) rgb(87 87 86 / 10%));
$elevation-06: drop-shadow(0 em(10px) em(24px) rgb(87 87 86 / 10%));
