$accountingMonthSelectBorderColor: #ccc;

.f4eGricsFileUploaded {
  display: flex;
  align-items: center;
  padding: 0.5em 0.75em;
  font-size: var(--novo-file-uploaded-font-size);
  line-height: var(--novo-file-uploaded-line-height);
  background-color: var(--novo-file-uploaded-bg-color);
  border: var(--novo-file-uploaded-border);
}

.f4eGricsFileUploaded--is-loading {
  --novo-circular-progress-color: var(--novo-file-uploaded-progress-color);
  --novo-circular-progress-font-size: var(--novo-file-uploaded-progress-font-size);
  --novo-circular-progress-height: var(--novo-file-uploaded-progress-size);
  --novo-circular-progress-width: var(--novo-file-uploaded-progress-size);

  background-color: var(--novo-file-uploaded-is-loading-bg-color);
}

.f4eGricsFileUploaded--faulty {
  background-color: var(--novo-file-uploaded-faulty-bg-color);
}

.f4eGricsFileUploaded__info {
  display: flex;
  flex: 1;
  align-items: center;
  padding-right: 1em;
}

.f4eGricsFileUploaded__info__icon {
  display: inline-block;
  margin: var(--novo-file-uploaded-icon-margin);
  font-size: var(--novo-file-uploaded-icon-font-size);
  color: var(--novo-file-uploaded-icon-color);
}

.f4eGricsFileUploaded__info__name {
  margin: 0;
  font-size: inherit;
  font-weight: normal;
  line-height: inherit;
}

.f4eGricsFileUploaded__info__accounting-month {
  margin-left: auto;

  .novoFormField__input--select {
    width: auto;
    padding-top: 0.1em;
    padding-bottom: 0.1em;
    border-color: $accountingMonthSelectBorderColor;
  }
}

.f4eGricsFileUploaded__status,
.f4eGricsFileUploaded__actions__button {
  font-size: var(--novo-file-uploaded-icon-font-size);
}

.f4eGricsFileUploaded__status {
  margin: var(--novo-file-uploaded-icon-margin);
  font-family: 'Material Icons', sans-serif;
  pointer-events: none;
}

.f4eGricsFileUploaded__status--loading {
  position: relative;
  top: -1px;
}

.f4eGricsFileUploaded__status--completed::before {
  color: var(--novo-file-uploaded-completed-color);
  content: 'check_circle';
}

.f4eGricsFileUploaded__status--fault::before {
  color: var(--novo-file-uploaded-fault-color);
  content: 'warning';
}

.f4eGricsFileUploaded__actions__button {
  padding: 0;
  margin: var(--novo-file-uploaded-action-button-margin);
  color: var(--novo-file-uploaded-icon-color);
  cursor: pointer;
  background-color: transparent;
  border: 0;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: var(--novo-file-uploaded-icon-color-hovered);
  }

  &:disabled {
    color: inherit;
    cursor: default;
    opacity: 0.3;
  }
}

.f4eGricsFileUploaded__actions__button--remove::before {
  content: 'close';
}

.novoDropzone__files-uploaded__item:not(:last-child) .f4eGricsFileUploaded {
  border-bottom: 0;
}
