@import '../../../../theme/helpers/units';

$reportLogoColor: #040570;

.f4eReport {
  max-width: 1500px;
  padding: 0 24px;
  margin: 0 auto 60px;
  break-inside: avoid;
}

.f4eReport__header {
  margin-bottom: 30px;
}

.f4eReport__header__print {
  display: none;
}

.f4eReport__header__title {
  margin-bottom: 10px;
  font-size: 25px;
  font-weight: bold;
}

.f4eReport__header__sub-title {
  font-weight: bold;
}

.f4eReport__header__mention {
  font-size: 15px;
  line-height: 1.65em;
}

.f4eReport__content {
  margin-bottom: 20px;
}

.f4eReport__content--no-break {
  break-inside: avoid;
}

@media print {
  .f4eReport {
    max-width: none;
    padding: 24px;
    margin: 0;
  }

  .f4eHeader {
    display: none;
  }

  .f4eLayout__header {
    display: none;
  }

  .f4eReport__header__print {
    display: flex;
    align-items: center;
  }

  .f4eReport__header__print__addr {
    flex-grow: 1;
    font-size: 8pt;
    line-height: 1.25em;
    color: gray;
    text-align: right;
  }

  .f4eReport__header__print__logo {
    display: block;
    width: em(180px);
    margin-bottom: 16pt;
    fill: $reportLogoColor;
  }

  .f4eReport__header__title {
    margin-bottom: 5px;
    font-size: 10pt;
    text-align: center;
  }

  .f4eReport__header__sub-title {
    font-size: 9pt;
    text-align: center;
  }

  .f4eReport__header__mention {
    margin-top: 3pt;
    font-size: 8pt;
    line-height: 1em;
    text-align: center;
  }

  .f4eLayout__content .novoForm--inline {
    display: none;
  }
}
