.f4eContributionReport {
  margin-top: 40px;
}

.f4eContributionReport__title,
.f4eContributionReport__active-contributors-count {
  padding: 0 15px;
}

.f4eContributionReport__title {
  font-size: 20px;
  font-weight: bold;
}

.f4eContributionReport__active-contributors-count {
  font-size: 15px;
  line-height: 1.5em;
}

.f4eContributionReport__table {
  width: 100%;
  table-layout: fixed;
  border-spacing: 10px 0;
  border-collapse: separate;
}

.f4eContributionReport__table thead th {
  font-size: 16px;
}

.f4eContributionReport__table tbody td,
.f4eContributionReport__table tfoot td {
  font-size: 14px;
}

.f4eContributionReport__table__header {
  font-weight: bold;
}

.f4eContributionReport__table__header:last-child .f4eContributionReport__table__cell {
  padding-bottom: 10px;
}

.f4eContributionReport__table__cell {
  padding: 5px;
}

.f4eContributionReport__table__cell--period {
  border-bottom: 1px solid black;
}

.f4eContributionReport__table__cell--period-total {
  width: 10%;
}

.f4eContributionReport__table__cell--period-separator {
  position: relative;
  width: 0;
  padding: 0;
}

.f4eContributionReport__table__cell--period-separator div {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  height: 100%;
  background-color: black;
}

.f4eContributionReport__table__cell--description {
  width: 22.5%;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.f4eContributionReport__table__cell--total {
  border-top: 1px solid black;
}

.f4eContributionReport__table__cell--amount {
  text-align: right;
}

.f4eContributionReport__table__cell--summary {
  text-align: right;
}

@media print {
  .f4eContributionReport {
    margin-top: 20pt;
  }

  .f4eContributionReport__title,
  .f4eContributionReport__active-contributors-count {
    padding: 0 12px;
  }

  .f4eContributionReport__title {
    padding-top: 4px;
    font-size: 9pt;
  }

  .f4eContributionReport__active-contributors-count,
  .f4eContributionReport__table thead th {
    font-size: 8pt;
  }

  .f4eContributionReport__table tbody td,
  .f4eContributionReport__table tfoot td {
    font-size: 6pt;
  }

  .f4eContributionReport__table__cell {
    padding: 2px;
  }
}
