@import '../base/colors';
@import '../helpers/units';

$tagFontSize: 12px;
$tagDefaultBackground: $light-gray;
$tagDarkBackground: rgb(127 140 143);
$tagMediumBackground: lighten($tagDarkBackground, 35%);
$tagDarkerBackground: $dark-gray;

.f4eTag {
  display: inline-block;
  padding: em(3px, $tagFontSize) em(16px, $tagFontSize);
  overflow: hidden;
  font-size: em($tagFontSize);
  font-weight: 600;
  line-height: em(16px, $tagFontSize);
  text-align: center;
  text-overflow: ellipsis;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  white-space: nowrap;
  vertical-align: sub;
  background-color: $tagDefaultBackground;
  border-radius: em(10px);
}

.f4eTag--full-width {
  width: 100%;
}

.f4eTag--dark {
  color: $white;
  background-color: $tagDarkBackground;
}

.f4eTag--darker {
  color: $white;
  background-color: $tagDarkerBackground;
}

.f4eTag--activeState {
  color: $white;
  background-color: $tagDarkBackground;

  &[data-value='active'] {
    background-color: $green;
  }
}

.f4eTag--memberStatus {
  &[data-value^='Active'] {
    background-color: $tagMediumBackground;
  }

  &[data-value^='Inactive'] {
    color: $white;
    background-color: $tagDarkerBackground;
  }
}

.f4eTag--membershipStatus {
  color: $white;
  background-color: $tagDarkBackground;

  &[data-value='completed'] {
    background-color: $green;
  }

  &[data-value='cancel'] {
    background-color: $red;
  }
}

.f4eTag--membershipProcessState {
  background-color: $tagMediumBackground;

  &[data-value='action-required'] {
    color: $white;
    background-color: $yellow;
  }

  &[data-value='completed'] {
    color: $white;
    background-color: $green;
  }
}

.f4eTag--gricsImportStatus {
  color: $white;

  &[data-value='queued'] {
    background-color: $tagDarkBackground;
  }

  &[data-value='processing'] {
    background-color: $blue;
  }

  &[data-value='completed'] {
    background-color: $green;
  }

  &[data-value='incomplete'],
  &[data-value='interrupted'] {
    background-color: $yellow;
  }

  &[data-value='error'] {
    background-color: $red;
  }
}

.f4eTag--gricsImportChangeStatus {
  color: $white;

  &[data-value='canceled'] {
    background-color: $dark-gray;
  }

  &[data-value='pending'] {
    background-color: $medium-gray;
  }

  &[data-value='accepted'] {
    background-color: $green;
  }

  &[data-value='refused'] {
    background-color: $red;
  }
}
