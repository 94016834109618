@import '../../theme/helpers/units';

$memberChangesActionFontSize: 18px;

.f4eMemberChanges__action-group {
  margin-bottom: em(38px);
}

.f4eMemberChanges__action-group__message {
  margin-bottom: em(8px, $memberChangesActionFontSize);
  font-size: em($memberChangesActionFontSize);
  font-weight: 600;
  line-height: em(24px, $memberChangesActionFontSize);
}

.f4eMemberChanges__contact {
  margin-top: -1.25em;
  line-height: 1.5em;
}

.f4eMemberChanges__contact__link {
  color: black;
  text-decoration: none;
}

.f4eMemberChanges__contact__link--email {
  text-decoration: underline;
}
