@import '../../../../theme/base/colors';
@import '../../../../theme/helpers/units';

$wizardStepColor: #7f8c8f;
$wizardStepActiveColor: black;
$wizardStepFontSize: 18px;
$wizardStepIconSize: 46px;
$wizardStepIconCompletedColor: $blue;
$wizardStepIconColor: $wizardStepColor;
$wizardStepIconBorderColor: $medium-gray;
$wizardStepIconActiveColor: white;
$wizardStepIconFontSize: 20px;
$wizardStepIconActiveBgColor: $blue;
$wizardStepSeparatorColor: $light-gray;
$wizardFooterBorderColor: $light-gray;
$wizardFooterBgColor: $lightest-gray;

.f4eWizard {
  display: flex;
  flex-direction: column;
  height: 100%;
  counter-reset: wizard-step;
}

.f4eWizard__steps {
  display: flex;
  justify-content: center;
  padding: em(18px);
}

.f4eWizard__steps__item {
  display: flex;
  align-items: center;
  font-size: em($wizardStepFontSize);
  font-weight: 600;
  color: $wizardStepColor;

  &::before {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: em($wizardStepIconSize, $wizardStepIconFontSize);
    height: em($wizardStepIconSize, $wizardStepIconFontSize);
    margin-right: em(10px, $wizardStepIconFontSize);
    font-size: em($wizardStepIconFontSize, $wizardStepFontSize);
    font-weight: 600;
    color: $wizardStepIconColor;
    content: counter(wizard-step);
    counter-increment: wizard-step;
    background-color: transparent;
    border: 2px solid $wizardStepIconBorderColor;
    border-radius: 50%;
  }

  &:not(:last-child)::after {
    width: em(20px);
    margin: 0 em(10px);
    content: '';
    border-top: 2px solid $wizardStepSeparatorColor;
  }
}

.f4eWizard__steps__item--active {
  color: $wizardStepActiveColor;

  &::before {
    color: white;
    background-color: $wizardStepIconActiveBgColor;
    border-color: $wizardStepIconActiveBgColor;
  }
}

.f4eWizard__steps__item--completed::before {
  font-family: 'Material Icons', sans-serif;
  color: $wizardStepIconCompletedColor;
  content: 'done';
  background-color: transparent;
  border-color: $wizardStepIconActiveBgColor;
}

.f4eWizard__footer {
  display: flex;
  justify-content: space-between;
  padding: em(24px);
  background-color: $wizardFooterBgColor;
  border-top: 1px solid $wizardFooterBorderColor;
}

.f4eWizard__content {
  flex: 1;
  overflow: hidden;
}
