@import '../../../../theme/base/colors';
@import '../../../../theme/helpers/units';

$statusBoxInfoIconColor: $blue;
$statusBoxInfoBgColor: rgba($blue, 0.05);
$statusBoxSuccessIconColor: $green;
$statusBoxSuccessBgColor: rgba($statusBoxSuccessIconColor, 0.05);
$statusBoxErrorIconColor: $red;
$statusBoxErrorBgColor: rgba($statusBoxErrorIconColor, 0.05);
$statusBoxWarningIconColor: $yellow;
$statusBoxWarningBgColor: rgba($statusBoxWarningIconColor, 0.1);
$statusBoxTitleFontSize: 20px;
$statusBoxTextFontSize: 18px;
$statusBoxCheckFontSize: 32px;

.f4eStatusBox {
  display: flex;
  align-items: flex-start;
  padding: em(26px);
  margin-bottom: em(30px);
}

.f4eStatusBox__icon {
  margin-right: em(16px, $statusBoxCheckFontSize);
  font-size: em($statusBoxCheckFontSize);
}

.f4eStatusBox__title {
  margin-bottom: em(6px, $statusBoxTitleFontSize);
  font-size: em($statusBoxTitleFontSize);
  font-weight: 700;
  line-height: em(28px, $statusBoxTitleFontSize);
  letter-spacing: -0.01em;
}

.f4eStatusBox__description {
  font-size: em($statusBoxTextFontSize);
  line-height: em(26px, $statusBoxTextFontSize);

  .novoButton {
    margin-top: 1.5em;
  }
}

.f4eStatusBox--info {
  background-color: $statusBoxInfoBgColor;

  .f4eStatusBox__icon {
    color: $statusBoxInfoIconColor;
  }

  .f4eStatusBox__icon::before {
    content: 'info';
  }
}

.f4eStatusBox--success {
  background-color: $statusBoxSuccessBgColor;

  .f4eStatusBox__icon {
    color: $statusBoxSuccessIconColor;
  }

  .f4eStatusBox__icon::before {
    content: 'check_circle';
  }
}

.f4eStatusBox--error {
  background-color: $statusBoxErrorBgColor;

  .f4eStatusBox__icon {
    color: $statusBoxErrorIconColor;
  }

  .f4eStatusBox__icon::before {
    content: 'error';
  }
}

.f4eStatusBox--warning {
  background-color: $statusBoxWarningBgColor;

  .f4eStatusBox__icon {
    color: $statusBoxWarningIconColor;
  }

  .f4eStatusBox__icon::before {
    content: 'warning';
  }
}
