@import '../../theme/base/colors';
@import '../../theme/helpers/units';
@import '../shared/components/Layout/SimpleLayout';

$confirmationIconFontSize: 40px;
$confirmationIconColor: $green;
$confirmationEmphasisFontSize: 18px;
$confirmationEmphasisBackgroundColor: $lightest-gray;
$orLineColor: $light-blue;
$orLineBgColor: $lightest-gray;
$orLineColorPrint: $medium-gray;

.f4eMembershipEmphasis {
  padding: em(19px, $confirmationEmphasisFontSize) 0;
  margin: em(24px, $confirmationEmphasisFontSize) 0;
  font-size: $confirmationEmphasisFontSize;
  font-weight: 600;
  line-height: em(32px, $confirmationEmphasisFontSize);
  text-align: center;
  background-color: $confirmationEmphasisBackgroundColor;
}

.f4eMembershipCompleted .f4eSimpleLayout__panel__content__title {
  position: relative;
  padding-left: em($confirmationIconFontSize + 16px, $simpleContentTitleFontSize);
}

.f4eMembershipCompleted__icon {
  position: absolute;
  top: 50%;
  left: 0;
  display: inline-block;
  font-size: em($confirmationIconFontSize, $simpleContentTitleFontSize);
  color: $confirmationIconColor;
  transform: translateY(-50%);
}

.f4eMembershipAuthorizePersonalInfo {
  --novo-field-label-required-symbol: '';

  width: 100%;
  margin-bottom: em(32px);
}

.f4eMembershipAuthorizePersonalInfo .novoFormField {
  margin: 0;
}

.f4eMembershipAuthorizePersonalInfo .novoFormField--error .novoFormFieldLabel {
  color: var(--novo-field-error-color);
}

.f4eMembershipAuthorizePersonalInfo .novoFormFieldError {
  display: none;
}

.f4eMembershipAuthorizePersonalInfo__or-line {
  position: relative;
  margin: em(20px) 0.75em;
  overflow: visible;
  border-bottom: 1px solid $orLineColor;

  &::after {
    position: absolute;
    top: -50%;
    left: 50%;
    padding: 0 0.5em;
    font-weight: bold;
    letter-spacing: -0.01em;
    content: 'OU';
    background: $orLineBgColor;
    transform: translate(-50%, -50%);
  }
}

@media print {
  .f4eMembershipAuthorizePersonalInfo__or-line {
    border-bottom: 1px solid $orLineColorPrint;
  }
}
