@import '../../theme/helpers/units';

$signInPanelHeadingFontSize: 32px;

.f4eSignIn__title {
  margin-bottom: em(16px, $signInPanelHeadingFontSize);
  font-size: em($signInPanelHeadingFontSize);
  font-weight: 700;
  line-height: em(48px, $signInPanelHeadingFontSize);
}
